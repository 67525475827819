import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

type Props = {
	loading?: boolean;
	error?: Error;
	payload?: object;
};

const ActionResult = ({ loading = false, error = undefined, payload = undefined }: Props) => {
	const { t } = useTranslation();

	if (loading) {
		return <CircularProgress style={{ margin: "auto", display: "block" }} />;
	}
	if (error) {
		return <div>{t("generic.errorWithMsg", { msg: "" })}<pre>{error.message}</pre></div>;
	}
	return <pre>{JSON.stringify(payload, null, 2)}</pre>;
};

export default ActionResult;
