import { Card, CardHeader, IconButton } from "@mui/material";
// services
import { icons } from "@local/theme";

interface GraphNode {
	id: string;
	name: string;
	isGateway: boolean;
	isListedDevice: boolean;
	icon: string;
	copyMacId: (nodeId: string) => void;
}

type Props = {
	node: GraphNode;
};

const CustomNode = ({ node }: Props) => (
	<Card style={{ backgroundColor: node.isGateway ? "#008fff" : (node.isListedDevice ? "#8fbc8f" : "#808080") }}>
		<CardHeader
			className="CardHeader-width-fix"
			avatar={
				<img src={node.icon} style={{ height: "40px", width: "40px" }} />
			}
			title={node.name}
			subheader={node.id}
			titleTypographyProps={{ noWrap: true, variant: "body2", style: { maxWidth: "140px", marginRight: "5px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.870588)" } }}
			subheaderTypographyProps={{ noWrap: true, variant: "body2", style: { color: "rgba(0, 0, 0, 0.870588)" } }}
			action={
				<IconButton onClick={() => node.copyMacId(node.id)} style={{ marginTop: "5px" }}>
					<icons.CopyContent htmlColor="rgba(0, 0, 0, 0.870588)" />
				</IconButton>
			}
		/>
	</Card>
);

export default CustomNode;
