import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";
// types
import type { CSSProperties } from "react";
import type { DeviceData } from "../../types/device";

type Props = {
	open: boolean;
	device?: DeviceData | null;
	onClose: () => void;
};

const defaultStyle = {
	whiteSpace: "normal",
	overflow: "inherit",
	wordBreak: "break-word",
} as const satisfies CSSProperties;
const attColumnStyle = {
	...defaultStyle,
	height: "auto",
} as const satisfies CSSProperties;
const keyAttColumnStyle = {
	...attColumnStyle,
	fontWeight: "bold",
	width: "auto",
} as const satisfies CSSProperties;

const DeviceAttributesInfoDialog = ({ open, device = null, onClose }: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			fullWidth={true}
			onClose={onClose}
		>
			<DialogTitle>{t("gateway.deviceAttributesInfoDialogTitle")}</DialogTitle>
			<DialogContent>
				<Table>
					<TableBody>
						{device && Object.keys(device.attributes).map((key) => (
							<TableRow key={key} className="last-row-no-border" style={{ height: "30px" }}>
								<TableCell sx={attColumnStyle}>{key}</TableCell>
								<TableCell sx={keyAttColumnStyle}>{device.attributes[key]}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{t("dialog.close")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeviceAttributesInfoDialog;
