import { Component, createRef } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Tab, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import GatewayGeneralInfo from "./gateway-general-info";
import GatewayAdvancedInfo from "./gateway-advanced-info";
import GatewayUsers from "./gateway-users";
import GatewayDevices from "./gateway-devices";
import GatewayRules from "./gateway-rules";
import GatewaySubscribers from "./gateway-subscribers";
import GatewayTerminal from "./gateway-terminal";
import GatewayHTTP from "./GatewayHttp";
import GatewayLogs from "./gateway-logs";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
import { TerminalType } from "../../services/terminal";
import { Storage, StorageKeys } from "../../services/storage";

const TAB_NAMES = {
	GENERAL: "general",
	ADVANCED: "advanced",
	USERS: "users",
	DEVICES: "devices",
	RULES: "rules",
	SUBSCRIBERS: "subscribers",
	CCC: "ccc",
	SHELL: "shell",
	HTTP: "http",
	LOGS: "logs",
};

class GatewayTabs extends Component {

	constructor(props) {
		super(props);

		this.hasCCCREAD = User.hasLevel("ccc_read");
		this.hasGetHTTP = Gupport.getGatewayHttp;
		this.hasGetLogs = Gupport.getGatewayLogs;

		let tab;
		if (this.props.params.tab) {
			Storage.set(StorageKeys.gatewaysTab, this.props.params.tab);
			tab = this.props.params.tab;
		} else if (Storage.get(StorageKeys.gatewaysTab)) {
			tab = Storage.get(StorageKeys.gatewaysTab);
		} else {
			tab = TAB_NAMES.GENERAL;
		}

		this.state = {
			// ready: Gupport.ready,
			selectedTab: tab,
		};

		this.references = {
			general: createRef(),
			advanced: createRef(),
			users: null,
			devices: null,
			rules: null,
			subscribers: createRef(),
			ccc: createRef(),
			shell: createRef(),
			http: null,
			logs: createRef(),
		};
		this.timeout = null;

		this.handleTabChanged = this.handleTabChanged.bind(this);
	}

	componentDidMount() {
		this.refresh();
	}

	componentDidUpdate(prevProps) {
		if (this.timeout) {
			window.clearTimeout(this.timeout);
		}
		if (this.props.params.tab && this.props.params.tab !== prevProps.params.tab) {
			const tab = this.props.params.tab;
			Storage.set(StorageKeys.gatewaysTab, tab);
			this.setState({
				selectedTab: tab,
			}, () => {
				this.refresh();
			});
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			window.clearTimeout(this.timeout);
		}
	}

	refresh() {
		this.timeout = window.setTimeout(() => {
			this.references[this.state.selectedTab]?.current?.refresh?.();
			this.refresh();
		}, 30_000);
	}

	handleTabChanged(event, value) {
		if (this.timeout) {
			window.clearTimeout(this.timeout);
		}
		this.props.navigate(`/gateways/${encodeURIComponent(this.props.gatewayId)}/${value}`);
	}

	render() {
		const { t } = this.props;

		if (this.props.params.gatewayId === undefined || this.props.params.tab === undefined) {
			if ((!this.hasCCCREAD && (this.state.selectedTab === "ccc" || this.state.selectedTab === "shell")) ||
				(!this.hasGetHTTP && this.state.selectedTab === "http") ||
				(!this.hasGetLogs && this.state.selectedTab === "logs")) {
				return <Navigate to={`/gateways/${encodeURIComponent(this.props.gatewayId)}/general`} replace={true} />;
			} else {
				return <Navigate to={`/gateways/${encodeURIComponent(this.props.gatewayId)}/${this.state.selectedTab}`} replace={true} />;
			}
		}

		return (
			<TabContext value={this.state.selectedTab}>
				<Paper style={{ marginBottom: "12px" }}>
					<TabList onChange={this.handleTabChanged}>
						<Tab label={t("gateway.info")} value={TAB_NAMES.GENERAL} />
						<Tab label={t("gateway.advanced")} value={TAB_NAMES.ADVANCED} />
						<Tab label={t("gateway.users")} value={TAB_NAMES.USERS} />
						<Tab label={t("gateway.devices")} value={TAB_NAMES.DEVICES} />
						<Tab label={t("gateway.rules")} value={TAB_NAMES.RULES} />
						<Tab label={t("gateway.subs")} value={TAB_NAMES.SUBSCRIBERS} />
						{this.hasCCCREAD && <Tab label={t("gateway.ccc")} value={TAB_NAMES.CCC} />}
						{this.hasCCCREAD && <Tab label={t("gateway.shell")} value={TAB_NAMES.SHELL} />}
						{this.hasGetHTTP && <Tab label={t("gateway.http")} value={TAB_NAMES.HTTP} />}
						{this.hasGetLogs && <Tab label={t("gateway.logs")} value={TAB_NAMES.LOGS} />}
					</TabList>
				</Paper>
				<TabPanel value={TAB_NAMES.GENERAL}><GatewayGeneralInfo ref={this.references.general} gatewayId={this.props.gatewayId} isAdvancedSearchEnabled={this.props.isAdvancedSearchEnabled} /></TabPanel>
				<TabPanel value={TAB_NAMES.ADVANCED}><GatewayAdvancedInfo ref={this.references.advanced} gatewayId={this.props.gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.USERS}><GatewayUsers gatewayId={this.props.gatewayId} gatewayLabel={this.props.gatewayLabel || this.props.gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.DEVICES}><GatewayDevices gatewayId={this.props.gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.RULES}><GatewayRules gatewayId={this.props.gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.SUBSCRIBERS}><GatewaySubscribers ref={this.references.subscribers} gatewayId={this.props.gatewayId} /></TabPanel>
				{this.hasCCCREAD && <TabPanel value={TAB_NAMES.CCC}><GatewayTerminal ref={this.references.ccc} gatewayId={this.props.gatewayId} module={TerminalType.CCC} /></TabPanel>}
				{this.hasCCCREAD && <TabPanel value={TAB_NAMES.SHELL}><GatewayTerminal ref={this.references.shell} gatewayId={this.props.gatewayId} module={TerminalType.SHELL} /></TabPanel>}
				{this.hasGetHTTP && <TabPanel value={TAB_NAMES.HTTP}><GatewayHTTP gatewayId={this.props.gatewayId} /></TabPanel>}
				{this.hasGetLogs && <TabPanel value={TAB_NAMES.LOGS}><GatewayLogs ref={this.references.logs} gatewayId={this.props.gatewayId} /></TabPanel>}
			</TabContext>
		);
	}

}

GatewayTabs.propTypes = {
	isAdvancedSearchEnabled: PropTypes.bool.isRequired,
	gatewayId: PropTypes.string.isRequired,
	gatewayLabel: PropTypes.string.isRequired,
	navigate: PropTypes.func.isRequired,
	params: PropTypes.shape({
		tab: PropTypes.string,
		gatewayId: PropTypes.string,
	}).isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(withNavigateAndParams(GatewayTabs));
