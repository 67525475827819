import { Component } from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import {
	Paper,
	CircularProgress,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// cmps
import Na from "../na";
import RowEntry from "../row-entry";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
import { muiTheme } from "@local/theme";

class GatewayGeneralInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			loading: true,
			searchError: null,
			gateway: null,
			userActivated: null,
			userDeactivated: null,
			nameEditMode: false,
			locationEditMode: false,
			error: {},
			buttonDisable: false,
			locationButtonDisable: false,
			openDialog: false,
			savingLocation: false,
			osTimeLoading: false,
			osDate: "",
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.fetchGateway = this.fetchGateway.bind(this);
		this.getGateway = this.getGateway.bind(this);
		this.fieldUpdate = this.fieldUpdate.bind(this);
		this.save = this.save.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.setEditMode = this.setEditMode.bind(this);
		this.saveName = this.saveName.bind(this);
		this.saveLocation = this.saveLocation.bind(this);
		this.getOsDate = this.getOsDate.bind(this);
		this.handleFetchUserResult = this.handleFetchUserResult.bind(this);
	}

	componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);

		this.fetchGateway(this.props.gatewayId);
	}

	componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
	}

	handleGupportReady() {
		// this.setState({
		// 	ready: Gupport.ready
		// });
	}

	fetchGateway(gatewayId) {
		if (Gupport.ready) {
			this.getGateway(gatewayId);
		} else {
			Gupport.once("ready", () => {
				this.getGateway(gatewayId);
			});
		}
	}

	getGateway(gatewayId) {
		const cmd = {
			action: "getGateway",
			gatewayId: gatewayId,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					loading: false,
					searchError: null,
					gateway: msg.payload.data,
					nameEditMode: false,
					locationEditMode: false,
					error: {},
				});
				if (msg.payload.data.activated_by) {
					this.fetchUser("userActivated", msg.payload.data.activated_by);
				}
				if (msg.payload.data.deactivated_by) {
					this.fetchUser("userDeactivated", msg.payload.data.deactivated_by);
				}
			} else {
				this.setState({
					loading: false,
					searchError: msg.payload.data || error,
					gateway: null,
					nameEditMode: false,
					locationEditMode: false,
					error: {},
				});
			}
		});
	}

	handleFetchUserResult(type, error, msg) {
		if (!error && msg?.payload.status === "ok") {
			this.setState({
				loading: false,
				searchError: null,
				[type]: msg.payload.data,
			});
		} else if (msg.payload.data === "notfound") {
			this.setState({
				loading: false,
				searchError: null,
				[type]: null,
			});
		} else {
			this.setState({
				loading: false,
				searchError: msg.payload.data || error,
				[type]: null,
			});
		}
	}

	fetchUser(type, username) {
		if (this.props.isAdvancedSearchEnabled) {
			const cmd = {
				action: "getB2CUser",
				sub: username,
			};
			Gupport.send(cmd, (error, msg) => (this.handleFetchUserResult(type, error, msg)));
		} else {
			const cmd = {
				action: "getUser",
				username: username,
			};
			Gupport.send(cmd, (error, msg) => (this.handleFetchUserResult(type, error, msg)));
		}
	}

	fieldUpdate(event) {
		const { t } = this.props;
		const target = event.target;
		this.setState((prevState) => {
			const gateway = prevState.gateway;
			const errorObj = {};
			if (target.name === "name") {
				gateway.name = target.value;
				if (target.value.length > 0) {
					errorObj.name = null;
				} else {
					errorObj.name = t("messages.required");
				}
			} else if (target.name === "location") {
				gateway.location = target.value;
				if (target.value.length > 0) {
					errorObj.location = null;
				} else {
					errorObj.location = t("messages.required");
				}
			}
			return {
				gateway: gateway,
				error: errorObj,
			};
		});
	}

	save() {
		this.setState({
			buttonDisable: true,
			saving: true,
		});
		const cmd = {
			action: "saveGateway",
			gatewayId: this.state.gateway.id,
			name: this.state.gateway.name,
			location: this.state.gateway.location,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					saving: false,
				});
			} else {
				this.setState({
					openDialog: true,
					// dialogMsg: error,
					saving: false,
				});
			}
		});
	}

	saveName() {
		if (!this.state.gateway.name) {
			return;
		}
		this.setState({
			saving: true,
			buttonDisable: true,
		});
		const cmd = {
			action: "saveGateway",
			gatewayId: this.state.gateway.id,
			name: this.state.gateway.name,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					saving: false,
					nameEditMode: false,
					buttonDisable: false,
				});
			} else {
				this.setState({
					openDialog: true,
					// dialogMsg: error,
					saving: false,
					buttonDisable: false,
				});
			}
		});
	}

	saveLocation() {
		if (!this.state.gateway.location) {
			return;
		}
		this.setState({
			savingLocation: true,
			locationButtonDisable: true,
		});
		const cmd = {
			action: "saveGateway",
			gatewayId: this.state.gateway.id,
			location: this.state.gateway.location,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					savingLocation: false,
					locationEditMode: false,
					locationButtonDisable: false,
				});
			} else {
				this.setState({
					openDialog: true,
					// dialogMsg: error,
					savingLocation: false,
					locationButtonDisable: false,
				});
			}
		});
	}

	handleClose() {
		this.setState({
			openDialog: false,
		});
	}

	setEditMode(name) {
		switch (name) {
			case "name":
				this.setState({
					nameEditMode: true,
				});
				break;
			case "location":
				this.setState({
					locationEditMode: true,
				});
				break;
		}
	}

	getEmail(user) {
		if (user.email) {
			return user.email;
		} else if (user.emails) {
			return user.emails.join(", ");
		} else {
			return user.username;
		}
	}

	getOsDate() {
		this.setState({
			osTimeLoading: true,
		});

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "get_os_date",
			params: [],
			gatewayId: this.props.gatewayId,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					osTimeLoading: false,
					error: null,
					osDate: msg.payload.data,
				});
			} else {
				this.setState({
					osTimeLoading: false,
					error: error,
					osDate: "error",
				});
			}
		});
	}

	render() {
		const { t } = this.props;
		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.searchError) {
			return <div>{t("generic.errorWithMsg", { msg: (typeof this.state.searchError === "object") ? JSON.stringify(this.state.searchError) : this.state.searchError })}</div>;
		}

		let createdAt, connected, disconnected, activated, deactivated;
		if (this.state.gateway) {
			const divider = 1000;
			createdAt = this.state.gateway.created ? new Date(this.state.gateway.created / divider).toLocaleString() : "";
			connected = this.state.gateway.connected
				? new Date(Number.isInteger(this.state.gateway.connected)
					? this.state.gateway.connected / divider
					: this.state.gateway.connected).toLocaleString()
				: "";
			disconnected = this.state.gateway.disconnected
				? new Date(Number.isInteger(this.state.gateway.disconnected)
					? this.state.gateway.disconnected / divider
					: this.state.gateway.disconnected).toLocaleString()
				: "";
			activated = this.state.gateway.activated ? new Date(this.state.gateway.activated / divider).toLocaleString() : "";
			deactivated = this.state.gateway.deactivated ? new Date(this.state.gateway.deactivated / divider).toLocaleString() : "";
		}
		const isSaveAvail = Boolean(Gupport.saveGateway);

		return (
			<>
				<Paper component="section" className="card-separator card-info">
					<RowEntry title={t("gateway.name")} styleRight={{ margin: "-10px 0" }}>
						{
							isSaveAvail ?
								(this.state.nameEditMode ? (
									<>
										<TextField
											name="name"
											placeholder="Name"
											value={this.state.gateway.name}
											// errorText={this.state.error.name}
											onChange={this.fieldUpdate}
											sx={{minWidth: "200px"}}
										/>
										<LoadingButton
											disabled={this.state.buttonDisable || Boolean(this.state.error.name)}
											loading={this.state.saving}
											onClick={this.saveName}
											style={{ marginLeft: "10px" }}
										>
											{t("messages.save")}
										</LoadingButton>
									</>
								) : (
									<>
										<span style={{ display: "inline-block", minWidth: "200px" }}>
											{this.state.gateway.name || <Na />}
										</span>
										<Button style={{ marginLeft: "10px" }} onClick={this.setEditMode.bind(this, "name")}>
											{t("messages.edit")}
										</Button>
									</>
								)) : (this.state.gateway.name || <Na />)
						}
					</RowEntry>
					<Divider />
					<RowEntry title={t("gateway.location")} styleRight={{ margin: "-10px 0" }}>
						{
							isSaveAvail ?
								(this.state.locationEditMode ? (
									<>
										<TextField
											name="location"
											placeholder="Location"
											value={this.state.gateway.location}
											// errorText={this.state.error.location}
											onChange={this.fieldUpdate}
											sx={{minWidth: "200px"}}
										/>
										<LoadingButton
											disabled={this.state.locationButtonDisable || Boolean(this.state.error.location)}
											loading={this.state.savingLocation}
											onClick={this.saveLocation}
											style={{ marginLeft: "10px" }}
										>
											{t("messages.save")}
										</LoadingButton>
									</>
								) : (
									<>
										<span style={{ display: "inline-block", minWidth: "200px" }}>
											{this.state.gateway.location || <Na />}
										</span>
										<Button style={{ marginLeft: "10px" }} onClick={this.setEditMode.bind(this, "location")}>
											{t("messages.edit")}
										</Button>
									</>
								)) : (this.state.gateway.location || <Na />)
						}
					</RowEntry>
				</Paper>
				<section>
					<h4 className="sub-section-header">{t("gateway.generalDetails")}</h4>
					<Paper className="card-separator card-info">
						<RowEntry title={t("gateway.id")}>
							{this.state.gateway.id}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.code")}>
							{this.state.gateway.code}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.tableChannel")}>
							{this.state.gateway.channel}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.sku")}>
							{this.state.gateway.sku || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.po")}>
							{this.state.gateway.po || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.pd")}>
							{this.state.gateway.pd || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.country")}>
							{this.state.gateway.country || <Na />}
						</RowEntry>
					</Paper>
				</section>
				{/*{isSaveAvail &&
					<div style={{textAlign: "right", padding: "5px 0 9px"}} className="card-separator">
						<LoadingButton
							variant="contained"
							disabled={this.state.buttonDisable}
							loading={this.state.saving}
							onClick={this.save}
						>
							{t("messages.save")}
						</LoadingButton>
					</div>
				}*/}
				<section>
					<h4 className="sub-section-header">{t("gateway.gMiscellaneous")}</h4>
					<Paper className="card-separator card-info">
						<RowEntry title={t("gateway.vendor")}>
							{["na502eu", "na502us", "na301zb", "na301eu", "na301us"].includes(this.state.gateway.sku)
								? "Sercomm"
								: this.state.gateway.vendor || <Na />
							}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.tzIana")}>
							{this.state.gateway.tz_iana || <Na />}
							{User.hasLevel("gw_read") && Gupport.gatewayAction &&
								<>
									<Button
										variant="contained"
										size="small"
										disabled={this.state.osTimeLoading}
										style={{ margin: "-10px 10px" }}
										onClick={this.getOsDate}
									>
										{t("gateway.getCurrentTime")}
									</Button>
									{this.state.osDate}
								</>
							}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.created")}>
							{createdAt ? <time dateTime={new Date(this.state.gateway.created / 1000).toISOString()}>{createdAt}</time> : <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.connected")}>
							{connected || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.disconnected")}>
							{disconnected || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.activated")}>
							{activated || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.activatedBy")} styleRight={{ margin: "-8px 0" }}>
							{this.state.userActivated ?
								<>
									<Trans
										i18nKey="gateway.activatedByInfo"
										values={{ name: this.state.userActivated.name, emailId: this.getEmail(this.state.userActivated) }}
										components={{
											a: <a
												href={`user_desc.html#/users/${this.state.userActivated.username}/general`}
												target="_blank"
												rel="noopener noreferrer"
												style={{ color: muiTheme.palette.primary.main, textDecoration: "none" }}
											/>,
										}}
									/>
									<div style={{ color: muiTheme.palette.text.secondary, fontSize: "11px" }}>{this.state.userActivated.username}</div>
								</>
								: this.state.gateway.activated_by ? this.state.gateway.activated_by : <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.deactivated")}>
							{deactivated || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.deactivatedBy")} styleRight={{ margin: "-8px 0" }}>
							{this.state.userDeactivated ?
								<>
									<Trans
										i18nKey="gateway.deactivatedByInfo"
										values={{ name: this.state.userDeactivated.name, emailId: this.getEmail(this.state.userDeactivated) }}
										components={{
											a: <a
												href={`user_desc.html#/users/${this.state.userDeactivated.username}/general`}
												target="_blank"
												rel="noopener noreferrer"
												style={{ color: muiTheme.palette.primary.main, textDecoration: "none" }}
											/>,
										}}
									/>
									<div style={{ color: muiTheme.palette.text.secondary, fontSize: "11px" }}>{this.state.userDeactivated.username}</div>
								</>
								: this.state.gateway.deactivated_by ? this.state.gateway.deactivated_by : <Na />}
						</RowEntry>
					</Paper>
				</section>
				<section>
					<h4 className="sub-section-header">{t("gateway.deprecated")}</h4>
					<Paper className="card-separator card-info">
						<RowEntry title={t("gateway.timezone")}>
							{this.state.gateway.timezone || <Na />}
						</RowEntry>
						<Divider />
						<RowEntry title={t("gateway.tz")}>
							{this.state.gateway.tz || <Na />}
						</RowEntry>
					</Paper>
				</section>
				<Dialog
					fullWidth={true}
					open={this.state.openDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("dialog.error")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("gateway.errorDialogContent")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

}

GatewayGeneralInfo.propTypes = {
	isAdvancedSearchEnabled: PropTypes.bool.isRequired,
	gatewayId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(GatewayGeneralInfo);
