import { useState, useEffect, useMemo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
	Paper,
	Button,
	TextField,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Autocomplete,
	Typography,
} from "@mui/material";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
//cmp
import Na from "../na";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
import { icons } from "@local/theme";
// types
import type { GridRowId, GridColDef } from "@mui/x-data-grid";
import type { CmdActivateGateway, CmdDeactivateGateway, CmdDisinviteUser, CmdGetUsers, CmdInviteUser, CmdSearch } from "../../types/gupport";
import type { GatewayId, UsersItem } from "../../types/gateway";

type Props = {
	gatewayId: GatewayId;
	gatewayLabel: string;
};

interface SelectedUser {
	id: string;
	label: string;
	primary: string;
	secondary: string;
}

const GatewayUsers = ({ gatewayId, gatewayLabel }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);
	const [owner, setOwner] = useState<UsersItem | undefined>(undefined);
	const [usersWithoutOwner, setUsersWithoutOwner] = useState<Array<UsersItem>>([]);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [buttonDisable, setButtonDisable] = useState(true);
	const [showErrorDialog, setShowErrorDialog] = useState(false);
	const [showInviteDialog, setShowInviteDialog] = useState(false);
	const [showActivateDialog, setShowActivateDialog] = useState(false);
	const [showDisinviteDialog, setShowDisinviteDialog] = useState(false);
	const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [options, setOptions] = useState<Array<SelectedUser>>([]);
	const [selectedUser, setSelectedUser] = useState<SelectedUser | UsersItem | undefined>(undefined);

	const isDisinviteUserAvail = Boolean(Gupport.disinviteUser);

	const getUsers = (gatewayId: GatewayId) => {
		const cmd = {
			action: "getUsers",
			gatewayId: gatewayId,
		} as const satisfies CmdGetUsers;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				const users = msg.payload.data;
				setError(null);
				setOwner(users.find((user) => (user.level === "owner")));
				setUsersWithoutOwner(users.filter((user) => (user.level !== "owner")));
			} else {
				setError(error?.message ?? msg.payload.data);
				setOwner(undefined);
				setUsersWithoutOwner([]);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (Gupport.ready) {
			getUsers(gatewayId);
		} else {
			Gupport.once("ready", () => {
				getUsers(gatewayId);
			});
		}
	}, [gatewayId]);

	const openInviteDialog = () => {
		setShowInviteDialog(true);
		setButtonDisable(true);
	};

	const openActivateDialog = () => {
		setShowActivateDialog(true);
		setButtonDisable(true);
	};

	const handleClose = () => {
		setShowErrorDialog(false);
		setShowInviteDialog(false);
		setShowActivateDialog(false);
		setShowDisinviteDialog(false);
		setShowDeactivateDialog(false);
	};

	const handleUpdateInput = (event, value) => {
		setOptionsLoading(true);

		const cmd = {
			action: "search",
			kind: "user",
			query: value,
		} as const satisfies CmdSearch;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				const optionsData: Array<SelectedUser> = msg.payload.data
					.filter((item, index, array) => (
						array.findIndex((el) => (el.id === item.id)) === index // TODO: remove deduplication in future
					))
					.map((user) => ({
						id: user.id,
						label: `${(user.name || user.username) as string} (${user.username as string})`,
						primary: user.name || user.username,
						secondary: user.username,
					}));

				optionsData.sort((optionA, optionB) => {
					const aText = (`${optionA.label}#${optionA.id}`).toLowerCase();
					const bText = (`${optionB.label}#${optionB.id}`).toLowerCase();
					return sortAlphabetically(aText, bText);
				});

				setOptions(optionsData);
				setButtonDisable(false);
			} else {
				setOptions([]);
			}
			setOptionsLoading(false);
		});
	};

	const handleNewRequest = (event, value/*, reason, details*/) => {
		if (value) {
			setButtonDisable(false);
			setSelectedUser(value);
		} else {
			setButtonDisable(true);
		}
	};

	const disinviteUser = () => {
		const cmd = {
			action: "disinviteUser",
			gatewayId: gatewayId,
			username: selectedUser?.username,
		} as const satisfies CmdDisinviteUser;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				getUsers(gatewayId);
			} else {
				setShowErrorDialog(true);
				setErrorMessage(t("gateway.disinvited"));
			}
			setSelectedUser(null);
			setShowDisinviteDialog(false);
		});
	};

	const inviteUser = () => {
		const cmd = {
			action: "inviteUser",
			gatewayId: gatewayId,
			username: selectedUser?.id,
		} as const satisfies CmdInviteUser;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				getUsers(gatewayId);
			} else {
				setShowErrorDialog(true);
				setErrorMessage(t("gateway.inviteError"));
			}
			setShowInviteDialog(false);
		});
	};

	const activateGateway = () => {
		const cmd = {
			action: "activateGateway",
			gatewayId: gatewayId,
			username: selectedUser?.id,
		} as const satisfies CmdActivateGateway;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				getUsers(gatewayId);
			} else {
				setShowErrorDialog(true);
				setErrorMessage(t("gateway.activateError"));
			}
			setShowActivateDialog(false);
		});
	};

	const deactivateGateway = () => {
		const cmd = {
			action: "deactivateGateway",
			gatewayId: gatewayId,
			username: selectedUser?.username,
		} as const satisfies CmdDeactivateGateway;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				getUsers(gatewayId);
			} else {
				setShowErrorDialog(true);
				setErrorMessage(t("gateway.deactivated"));
			}
			setSelectedUser(null);
			setShowDeactivateDialog(false);
		});
	};

	const openDisinviteDialog = useCallback((id: GridRowId) => (
		() => {
			setSelectedUser(usersWithoutOwner.find((user) => (user.username === id)));
			setShowDisinviteDialog(true);
		}
	), [usersWithoutOwner]);

	const openDeactivateDialog = useCallback(() => (
		() => {
			setSelectedUser(owner);
			setShowDeactivateDialog(true);
		}
	), [owner]);

	const columns = useMemo(() => ([
		{
			field: "email",
			headerName: t("gateway.username"),
			flex: 3,
			renderCell: (params) => (
				<a href={`user_desc.html#/users/${params.row.username}/general`} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
					{params.row.email || params.row.emails?.join(", ") || params.row.username}
				</a>
			),
			valueGetter: (_value, row) => (row.email || row.emails?.join(", ") || row.username),
		},
		{
			field: "username",
			headerName: t("gateway.userId"),
			flex: 3,
		},
		{
			field: "name",
			headerName: t("gateway.uName"),
			flex: 1,
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "level",
			headerName: t("gateway.level"),
			flex: 1,
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "actions",
			headerName: "Action",
			type: "actions",
			flex: 1,
			// eslint-disable-next-line react/no-unstable-nested-components
			getActions: (params) => (
				(params.row.level === "owner") ? [
					<GridActionsCellItem
						key={params.id}
						label="Deactivate"
						icon={<icons.BlockIcon color="danger" />}
						onClick={openDeactivateDialog()}
					/>,
				] : (params.row.level === "user" && isDisinviteUserAvail) ? [
					<GridActionsCellItem
						key={params.id}
						label="Disinvite"
						icon={<Svg src="actions/removeCircleOutlined.svg" color={theme.palette.danger.main} />}
						onClick={openDisinviteDialog(params.id)}
					/>,
				] : []
			),
		},
	] as const satisfies ReadonlyArray<GridColDef<UsersItem>>), [t, theme.palette.primary.main, theme.palette.danger.main, isDisinviteUserAvail, openDeactivateDialog, openDisinviteDialog]);

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<>
			<section style={{ marginBottom: "16px" }}>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
					<Typography variant="subtitle1">{t("gateway.owner")}</Typography>
					{Boolean(Gupport.activateGateway) &&
						<Button disabled={loading || owner !== undefined} variant="contained" onClick={openActivateDialog}>{t("gateway.activateGt")}</Button>
					}
				</div>
				<Paper>
					<DataGrid
						loading={loading}
						columns={columns}
						rows={(owner === undefined) ? [] : [owner]}
						getRowId={(row) => (row.username)}
					/>
				</Paper>
			</section>
			<section style={{ marginBottom: "16px" }}>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
					<Typography variant="subtitle1">{t("gateway.user", { count: usersWithoutOwner.length })}</Typography>
					{Boolean(Gupport.inviteUser) &&
						<Button disabled={loading} variant="contained" onClick={openInviteDialog}>{t("gateway.inviteUser")}</Button>
					}
				</div>
				<Paper>
					<DataGrid
						loading={loading}
						columns={columns}
						rows={usersWithoutOwner}
						getRowId={(row) => (row.username)}
					/>
				</Paper>
			</section>
			<Dialog
				fullWidth={true}
				open={showErrorDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("dialog.error")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{errorMessage}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				open={showInviteDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("gateway.inviteUser")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t("gateway.inviteUserMsg")} <strong>{gatewayLabel}</strong>
					</DialogContentText>
					<Autocomplete
						id="autocomplete_invite_user"
						fullWidth={true}
						loading={optionsLoading}
						options={options}
						filterOptions={createFilterOptions({ ignoreCase: true })}
						isOptionEqualToValue={(option, value) => (option.id === value.id)}
						onInputChange={handleUpdateInput}
						onChange={handleNewRequest}
						renderInput={(params) => (
							<TextField autoFocus={true} {...params} placeholder={t("gateway.userHint")} />
						)}
						renderOption={(props, option/*, state*/) => (
							<li {...props}><ListItemText primary={option.primary} secondary={option.secondary} /></li>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button disabled={selectedUser === null || buttonDisable} onClick={inviteUser}>{t("gateway.invite")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={showActivateDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("gateway.activateGt")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t("gateway.activateMsg")}
					</DialogContentText>
					<Autocomplete
						id="autocomplete_activate_gateway"
						fullWidth={true}
						loading={optionsLoading}
						options={options}
						filterOptions={createFilterOptions({ ignoreCase: true })}
						isOptionEqualToValue={(option, value) => (option.id === value.id)}
						onInputChange={handleUpdateInput}
						onChange={handleNewRequest}
						renderInput={(params) => (
							<TextField autoFocus={true} {...params} placeholder={t("gateway.userHint")} />
						)}
						renderOption={(props, option/*, state*/) => (
							<li {...props}><ListItemText primary={option.primary} secondary={option.secondary} /></li>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button disabled={selectedUser === null || buttonDisable} onClick={activateGateway}>{t("gateway.activateBtn")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={showDeactivateDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("gateway.deactivateDialogTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans
							i18nKey="gateway.deactivateDialogMsg"
							values={{ label: gatewayLabel }}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={deactivateGateway}>{t("gateway.deactivate")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={showDisinviteDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("gateway.disinviteDialogTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans
							i18nKey="gateway.disinviteDialogMsg"
							values={{ name: selectedUser?.username }}
						/>
						<Trans
							i18nKey="gateway.msgFrom"
							values={{ from: gatewayLabel }}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={disinviteUser}>{t("gateway.disinvite")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default GatewayUsers;
