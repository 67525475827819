import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { CircularProgress, IconButton } from "@mui/material";
// cmp
import withNavigateAndParams from "../cmp/withNavigateAndParams";
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import GatewayTabs from "../cmp/gateway/gateway-tabs";
import SearchAutocomplete from "../cmp/SearchAutocomplete";
import Svg from "../cmp/svg";
// services
import Constants from "../services/constants";
import Gupport from "../services/gupport";
import { Storage, StorageKeys } from "../services/storage";
import { muiTheme } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { CmdGetGateway } from "../types/gupport";
import type { GatewayId } from "../types/gateway";
import type { ReactRouterProps } from "../types/misc";

type Props = WithTranslation & ReactRouterProps & {
	separatePage?: boolean;
};

type State = {
	ready: boolean;
	gatewayId: GatewayId | null;
	loading: boolean;
	// error: any | null; // TODO
	gateway: any | null; // TODO
	advancedSearchEnabled: boolean;
};

class GatewaysPage extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		let gatewayId;
		if (this.props.params.gatewayId) {
			gatewayId = decodeURIComponent(this.props.params.gatewayId);
			Storage.set(StorageKeys.gatewayId, gatewayId);
		} else if (Storage.get(StorageKeys.gatewayId)) {
			gatewayId = Storage.get(StorageKeys.gatewayId);
		} else {
			gatewayId = null;
		}

		this.state = {
			ready: Gupport.ready,
			gatewayId: gatewayId,
			loading: false,
			// error: null,
			gateway: null,
			advancedSearchEnabled: false,
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.fetchGateway = this.fetchGateway.bind(this);
		this.handleOpenNewWindow = this.handleOpenNewWindow.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
		this.handleItemSelected = this.handleItemSelected.bind(this);
		this.handleCleared = this.handleCleared.bind(this);
	}

	override componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);

		if (Gupport.ready && this.state.gatewayId) {
			this.fetchGateway(this.state.gatewayId);
		}
	}

	override componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.params.gatewayId && decodeURIComponent(this.props.params.gatewayId) !== prevState.gatewayId) {
			this.setState({
				gatewayId: decodeURIComponent(this.props.params.gatewayId),
			}, () => {
				Storage.set(StorageKeys.gatewayId, this.state.gatewayId);

				if (Gupport.ready && this.state.gatewayId) {
					this.fetchGateway(this.state.gatewayId);
				}
			});
		}
	}

	override componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
	}

	handleGupportReady() {
		this.setState({
			ready: Gupport.ready,
		});

		if (Gupport.ready && this.state.gatewayId) {
			this.fetchGateway(this.state.gatewayId);
		}
	}

	handleToggleChange(isEnabled: boolean) {
		this.setState({
			advancedSearchEnabled: isEnabled,
		});
	}

	fetchGateway(gatewayId: GatewayId) {
		this.setState({
			loading: true,
		}, () => {
			const cmd = {
				action: "getGateway",
				gatewayId: gatewayId,
			} as const satisfies CmdGetGateway;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						loading: false,
						// error: error,
						gateway: msg.payload.data,
					});
				} else {
					this.setState({
						loading: false,
						// error: error || msg.payload.data,
						gateway: null,
					});
				}
			});
		});
	}

	handleOpenNewWindow() {
		if (this.state.gatewayId) {
			window.open(
				`gateway_desc.html#/gateways/${encodeURIComponent(this.state.gatewayId)}/general`,
				"_blank"
			);
		}
	}

	handleItemSelected(item) {
		const itemId = encodeURIComponent(item.id);
		if (this.props.params.gatewayId !== itemId) {
			if (this.props.params.tab) {
				this.props.navigate(`/gateways/${itemId}/${this.props.params.tab}`);
			} else {
				this.props.navigate(`/gateways/${itemId}/general`);
			}
		}
	}

	handleCleared() {
		Storage.remove(StorageKeys.gatewayId);
		Storage.remove(StorageKeys.gatewaysTab);

		this.setState({
			gatewayId: null,
			loading: false,
			// error: null,
			gateway: null,
		}, () => {
			this.props.navigate("/gateways");
		});
	}

	renderPage() {
		if (!this.state.ready) {
			return <CircularProgress />;
		}
		if (!this.state.gatewayId) {
			return null;
		}
		if (this.state.loading || !this.state.gateway) {
			return <CircularProgress />;
		}

		return (
			<div>
				<GatewayTabs
					isAdvancedSearchEnabled={this.state.advancedSearchEnabled}
					gatewayId={this.state.gateway.id}
					gatewayLabel={this.state.gateway.name || this.state.gateway.id}
				/>
			</div>
		);
	}

	override render() {
		const { t, separatePage } = this.props;
		const title = this.state.ready ? (
			<SearchAutocomplete
				placeholder={t("gateway.searchHint")}
				value={this.state.gateway ? `${this.state.gateway.name ?? this.state.gateway.id} (${this.state.gateway.id}) [${this.state.gateway.code}]` : ""}
				kind={Constants.Kind.Gateway}
				onItemSelected={this.handleItemSelected}
				onClear={this.handleCleared}
				onAdvanceSearchToggle={this.handleToggleChange}
			/>
		) : null;

		const iconElementRight = separatePage ? null : (
			<IconButton disabled={!this.state.gateway} onClick={this.handleOpenNewWindow}>
				<Svg src="navigation/openNewWindow.svg" color={this.state.gateway ? muiTheme.palette.primary.contrastText : "rgba(255,255,255,.7)"} />
			</IconButton>
		);

		return (
			<ResponsivePagePanel
				activeRoute="/gateways"
				title={title}
				iconElementRight={iconElementRight}
				hideNavigationDrawer={separatePage}
				hideNavigationMenu={separatePage}
			>
				{this.renderPage()}
			</ResponsivePagePanel>
		);
	}

}

GatewaysPage.defaultProps = {
	separatePage: false,
};

GatewaysPage.propTypes = {
	separatePage: PropTypes.bool,
	navigate: PropTypes.func.isRequired,
	params: PropTypes.shape({
		gatewayId: PropTypes.string,
		tab: PropTypes.string,
	}).isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(withNavigateAndParams(GatewaysPage));
