import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// services
import Gupport from "../../services/gupport";

class GatewayLogs extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			logs: [],
		};
	}

	componentDidMount() {
		this.fetchGatewayLogs(this.props.gatewayId);
	}

	fetchGatewayLogs(gatewayId) {
		if (Gupport.ready) {
			this.getGatewayLogs(gatewayId);
		} else {
			Gupport.once("ready", () => {
				this.getGatewayLogs(gatewayId);
			});
		}
	}

	getGatewayLogs(gatewayId) {
		const { t } = this.props;
		if (Gupport.getGatewayLogs) {
			const cmd = {
				action: "getGatewayLogs",
				gatewayId: gatewayId,
			};
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						loading: false,
						error: null,
						logs: msg.payload.data,
					});
				} else {
					this.setState({
						loading: false,
						error: msg.payload.data,
						logs: [],
					});
				}
			});
		} else {
			this.setState({
				loading: false,
				error: t("users.lError"),
				logs: [],
			});
		}
	}

	render() {
		const { t } = this.props;
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const columns = [
			{
				field: "logText",
				headerName: t("gateway.gText"),
				flex: 6,
			},
			{
				field: "_timestamp",
				headerName: t("gateway.gTimestamp"),
				flex: 1,
				renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>),
			},
			{
				field: "level",
				headerName: t("gateway.gLevel"),
				flex: 1,
			},
		];

		return (
			<Paper>
				<DataGrid
					loading={this.state.loading}
					columns={columns}
					rows={this.state.logs}
				/>
			</Paper>
		);
	}

}

GatewayLogs.propTypes = {
	gatewayId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(GatewayLogs);
