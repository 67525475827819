import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	CircularProgress,
	Divider,
	Paper,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	TableFooter,
	IconButton,
	Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
//cmp
import Na from "../na";
import Svg from "../svg";
import Doughnut from "../charts/doughnut-chart";
import DeviceInfoDialog from "./device-info-dialog";
import DeviceAttributesInfoDialog from "./DeviceAttributesInfoDialog";
import ZigbeeNetworkMap from "./zigbee-network-map/zigbee-network-map";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
import { icons } from "@local/theme";
import Constants from "../../services/constants";
// types
import type { MouseEvent } from "react";
import type { GridRowParams, GridColDef, GridFilterItem, GridFilterModel } from "@mui/x-data-grid";
import type { ChartData, ChartOptions, ChartEvent, LegendItem, ActiveElement } from "chart.js";
import type { CmdGetDevices, CmdGetTable } from "../../types/gupport";
import type { GatewayId } from "../../types/gateway";
import type { DevicesData, DeviceData, ReachableStatus } from "../../types/device";
import type { RocId, RocIdData } from "../../types/roc-table";

const DEVTYPE_COLORS = [
	"#3f51b5",
	"#00bcd4",
	"#ff9800",
	"#4caf50",
	"#ffeb3b",
	"#f44336",
	"#9c27b0",
	"#cddc39",
] as const;

const DEVICE_STATUS = [
	{
		type: Constants.Device.Value.ReachableStatus.Reachable,
		color: "#4caf50",
	},
	{
		type: Constants.Device.Value.ReachableStatus.Unreachable,
		color: "#ff9800",
	},
] as const;

const defaultStyle = {
	whiteSpace: "normal",
	overflow: "inherit",
	wordBreak: "break-word",
} as const;

type Props = {
	gatewayId: GatewayId;
};

const GatewayDevices = ({ gatewayId }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [showAttributeDetailsDialog, setShowAttributeDetailsDialog] = useState(false);
	const [tableData, setTableData] = useState<Array<RocIdData>>([]);
	const [device, setDevice] = useState<DeviceData | null>(null);
	const [isThirdParty, setIsThirdParty] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [devices, setDevices] = useState<DevicesData>([]);
	const [statusType, setStatusType] = useState<ReachableStatus | null>(null);
	const [deviceTypeCounts, setDeviceTypeCounts] = useState<Record<string, number>>({});
	const [devType, setDevType] = useState<string | null>(null);
	const [gridFilter, setGridFilter] = useState<Array<GridFilterItem>>([]);

	useEffect(() => {
		const getTable = () => {
			const cmd = {
				action: "getTable",
				tableName: "rocid_dict",
			} as const satisfies CmdGetTable;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setTableData(msg.payload.data.map((entry) => (entry.data)));
				}
			});
		};

		const getDevices = (gatewayId: GatewayId) => {
			const cmd = {
				action: "getDevices",
				gatewayId: gatewayId,
			} as const satisfies CmdGetDevices;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setError(null);
					const sortedDevices = msg.payload.data.sort((deviceA, deviceB) => (sortAlphabetically(deviceA.rocId, deviceB.rocId)));
					setDevices(sortedDevices);

					const _deviceTypeCounts: Record<string, number> = {};
					sortedDevices
						.map((device) => (device.attributes["Device Type"]))
						.forEach((deviceType) => {
							_deviceTypeCounts[deviceType] = _deviceTypeCounts[deviceType] ? ++_deviceTypeCounts[deviceType] : 1;
						});
					setDeviceTypeCounts(_deviceTypeCounts);
				} else {
					setError(t("gateway.noGateways"));
					setDevices([]);
				}
				setLoading(false);
			});
		};

		if (Gupport.ready) {
			getTable();
			getDevices(gatewayId);
		} else {
			Gupport.once("ready", () => {
				getTable();
				getDevices(gatewayId);
			});
		}
	}, [gatewayId]);

	// 11.07.2018 every user has the permission to read the rocid_dict table (hardcoded on server),
	// so we don't need this check anymore
	const hasReadRightRocidDict = true; //User.user.table_read.includes("rocid_dict");

	const handleDialogOpenClick = useCallback((isThirdParty: boolean, device: DeviceData) => {
		setIsThirdParty(isThirdParty);
		setDevice(device);
		setOpen(true);
	}, []);

	const getMfg = useCallback((device: DeviceData) => {
		const rocData = tableData.find((rocIdTableEntry) => (rocIdTableEntry.rocid === device.rocId));
		const fw = device.attributes["0006"] ? `(FW ${device.attributes["0006"]})` : null;
		return [rocData?.mfg_name, rocData?.mfg_model, fw].join(" ");
	}, [tableData]);

	const handleOpenHelpClick = useCallback((event: MouseEvent<HTMLButtonElement>, rocData: RocIdData) => {
		event.stopPropagation();
		window.open(rocData.mfg_help, "_blank");
	}, []);

	const getHelpIconButton = useCallback((rocId: RocId) => {
		const rocData = tableData.find((rocIdTableEntry) => (rocIdTableEntry.rocid === rocId));
		if (rocData?.mfg_help) {
			return (
				<IconButton onClick={(event) => (handleOpenHelpClick(event, rocData))}>
					<Svg src="navigation/openNewWindow.svg" color={theme.palette.primary.main} />
				</IconButton>
			);
		}
		return null;
	}, [tableData, handleOpenHelpClick, theme.palette.primary.main]);

	const columns = useMemo(() => ([
		{
			field: "id",
			headerName: t("gateway.id"),
			flex: 4,
			renderCell: (params) => (<pre style={{ margin: 0 }}>{params.value}</pre>),
		},
		{
			field: "deviceType",
			headerName: "Device type",
			hide: true,
			type: "singleSelect",
			valueOptions: Object.keys(deviceTypeCounts),
			valueGetter: (_value, row) => (row.attributes["Device Type"]),
		},
		{
			field: "name",
			headerName: t("gateway.name"),
			flex: 5,
			renderCell: (params) => (params.value || <Na />),
		},
		{
			field: "FF01",
			headerName: t("gateway.status"),
			flex: 2,
			type: "singleSelect",
			valueOptions: DEVICE_STATUS.map((status) => (status.type)),
			renderCell: (params) => (<Svg src={`${params.value as string}.svg`} size={24} />),
		},
		{
			field: "FF00",
			headerName: t("gateway.power"),
			flex: 2,
			renderCell: (params) => (
				(params.value || params.row.power) ? <Svg src={`power/${(params.value ?? params.row.power) as string}.svg`} size={24} /> : <Na />
			),
		},
		{
			field: "rocId",
			headerName: t("gateway.rocid"),
			flex: 2,
			renderCell: (params) => (params.value || <Na />),
		},
		{
			field: "mfg",
			headerName: t("gateway.mfg"),
			flex: 5,
			renderCell: (params) => (hasReadRightRocidDict ? getMfg(params.row) : <Na />),
		},
		{
			field: "rssi/lqi",
			headerName: `${t("gateway.rssi")}/${t("gateway.lqi")}`,
			flex: 2,
			renderCell: (params) => (<>{params.row.rssi ?? <Na />}/{params.row.lqi ?? <Na />}</>),
		},
		{
			field: "manual",
			headerName: t("gateway.manual"),
			flex: 2,
			renderCell: (params) => (hasReadRightRocidDict ? getHelpIconButton(params.row.rocId) : <Na />),
		},
		{
			field: "actions",
			headerName: t("gateway.raw"),
			type: "actions",
			flex: 2,
			// eslint-disable-next-line react/no-unstable-nested-components
			getActions: (params) => (
				(params.row.attributes["Device Type"] === Constants.Gateway.Sku.Tuya && !tableData.find((rocIdTableEntry) => (rocIdTableEntry.rocid === params.row.rocId))?.is_group) ? [
					<GridActionsCellItem
						key={params.id}
						label={t("gateway.raw")}
						icon={<icons.RawOn />}
						onClick={() => (handleDialogOpenClick(false, params.row))}
					/>,
					<GridActionsCellItem
						key={params.id}
						label={t("gateway.thirdParty")}
						icon={<icons.ExtensionIcon />} // need to updated this icon to thirdParty
						onClick={() => (handleDialogOpenClick(true, params.row))}
					/>,
				] : [
					<GridActionsCellItem
						key={params.id}
						label={t("gateway.raw")}
						icon={<icons.RawOn />}
						onClick={() => (handleDialogOpenClick(false, params.row))}
					/>,
				]
			),
		},
	] as const satisfies ReadonlyArray<GridColDef<DeviceData>>), [t, deviceTypeCounts, hasReadRightRocidDict, getMfg, getHelpIconButton, handleDialogOpenClick, tableData]);

	const deviceStatus = DEVICE_STATUS.map((devStatus) => ({
		...devStatus,
		count: devices.filter((dev) => (dev.FF01 === devStatus.type)).length,
	}));

	const handleRowClick = useCallback((gridRowParams: GridRowParams) => {
		setDevice(gridRowParams.row);
		setShowAttributeDetailsDialog(true);
	}, []);

	const handleOnFilterModelChange = useCallback((filter: GridFilterModel) => {
		const filterItem = filter.items.find((item) => (["FF01", "deviceType"].includes(item.field) && item.operator === "is"));
		if (filter.items.length === 0 || filterItem?.value === undefined) {
			if (filterItem?.field === "FF01") {
				setStatusType(null);
			} else if (filterItem?.field === "deviceType") {
				setDevType(null);
			}
		} else if (filterItem.field === "FF01" && deviceStatus.some((status) => (status.type === filterItem.value))) {
			setStatusType(filterItem.value);
		} else if (filterItem.field === "deviceType" && Object.keys(deviceTypeCounts).includes(filterItem.value)) {
			setDevType(filterItem.value);
		}
		setGridFilter(filter.items);
	}, [deviceStatus, deviceTypeCounts]);

	if (loading) {
		return <CircularProgress />;
	}
	if (error) {
		return <div>{error}</div>;
	}

	const handleDialogCloseClick = () => {
		setOpen(false);
		setIsThirdParty(false);
		setDevice(null);
	};

	const deviceTypes = Object.keys(deviceTypeCounts);
	const deviceTypeBgColors = deviceTypes.map((type, index) => ({
		type: type,
		color: DEVTYPE_COLORS[index],
	}));

	const dataDeviceType = {
		labels: Object.keys(deviceTypeCounts).map((deviceType) => (deviceType)),
		datasets: [{
			data: Object.keys(deviceTypeCounts).map((deviceType) => (deviceTypeCounts[deviceType])),
			backgroundColor: (devType === null)
				? deviceTypeBgColors.map((dev) => (dev.color))
				: deviceTypeBgColors.map((dev) => ((dev.type === devType) ? dev.color : grey[400])),
		}],
	};

	const updateGridFilter = (isDeviceType: boolean, selectedType: string | null) => {
		if (selectedType === devType || selectedType === statusType || selectedType === null) {
			setDevType(null);
			setStatusType(null);
			setGridFilter([]);
		} else if (isDeviceType) {
			setGridFilter([{ field: "deviceType", operator: "is", value: selectedType }]);
			setDevType(selectedType);
			setStatusType(null);
		} else {
			setGridFilter([{ field: "FF01", operator: "is", value: selectedType }]);
			setStatusType(selectedType);
			setDevType(null);
		}
	};

	const dataDevStatus = {
		labels: deviceStatus.map((devStatus) => (devStatus.type)),
		datasets: [{
			data: deviceStatus.map((devStatus) => (devStatus.count)),
			backgroundColor: (statusType === null)
				? deviceStatus.map((devStatus) => (devStatus.color))
				: deviceStatus.map((devStatus) => (devStatus.type === statusType ? devStatus.color : grey[400])),
		}],
	} satisfies ChartData<"doughnut">;

	const optionsDevType = {
		animation: false,
		layout: {
			padding: 4,
		},
		cutout: "70%",
		plugins: {
			legend: {
				display: true,
				position: "top",
				labels: {
					boxWidth: 30,
					padding: 4,
					font: { size: 10 },
				},
				onClick: (event: ChartEvent, legendItem: LegendItem) => (updateGridFilter(true, deviceTypes[legendItem.index])),
			},
			title: {
				display: true,
				text: "Device Type",
				padding: 1,
				font: { size: 16 },
			},
		},
		onClick: (event: ChartEvent, elements: Array<ActiveElement>/*, chart: Chart*/) => (updateGridFilter(true, deviceTypes[elements[0].index])),
	} as const satisfies ChartOptions<"doughnut">;

	const optionsDevStatus = {
		radius: "80%",
		animation: false,
		layout: {
			padding: 4,
		},
		cutout: "70%",
		plugins: {
			legend: {
				display: true,
				position: "top",
				labels: {
					boxWidth: 40,
					padding: 4,
					font: { size: 10 },
				},
				onClick: (event: ChartEvent, legendItem: LegendItem) => (updateGridFilter(false, deviceStatus[legendItem.index].type)),
			},
			title: {
				display: true,
				text: "Reachability",
				padding: 1,
				font: { size: 16 },
			},
		},
		onClick: (event: ChartEvent, elements: Array<ActiveElement>/*, chart: Chart*/) => (updateGridFilter(false, deviceStatus[elements[0].index].type)),
	} as const satisfies ChartOptions<"doughnut">;

	return (
		<Grid container={true} spacing={1}>
			<Grid item={true} xs={2}>
				<Paper style={{ overflow: "hidden" }}>
					<Doughnut data={dataDeviceType} options={optionsDevType} />
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>{t("rocIds.dt_default_text")}</TableCell>
								<TableCell align="right">{t("gateway.count")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(deviceTypeCounts)
								.sort((typeA, typeB) => (sortAlphabetically(typeA, typeB)))
								.map((deviceType) => (
									<TableRow
										key={deviceType}
										hover={true}
										selected={devType === deviceType}
										onClick={() => (updateGridFilter(true, deviceType))}
										style={{ cursor: "pointer" }}
									>
										<TableCell style={defaultStyle}>{deviceType}</TableCell>
										<TableCell align="right" style={defaultStyle}>{deviceTypeCounts[deviceType]}</TableCell>
									</TableRow>
								))}
						</TableBody>
						<TableFooter>
							<TableRow
								className="last-row-no-border"
								onClick={() => (updateGridFilter(true, null))}
								style={{ cursor: "pointer" }}
							>
								<TableCell variant="head">{t("gateway.total")}</TableCell>
								<TableCell variant="head" align="right">{devices.length}</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
				<Paper sx={{ marginTop: 1, overflow: "hidden" }}>
					<Doughnut data={dataDevStatus} options={optionsDevStatus} />
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell style={defaultStyle}>{t("gateway.status")}</TableCell>
								<TableCell align="right" style={defaultStyle}>{t("gateway.count")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{deviceStatus.map((deviceStatus) => (
								<TableRow
									key={deviceStatus.type}
									hover={true}
									selected={statusType === deviceStatus.type}
									onClick={() => (updateGridFilter(false, deviceStatus.type))}
									style={{ cursor: "pointer" }}
								>
									<TableCell>{deviceStatus.type}</TableCell>
									<TableCell align="right">{deviceStatus.count}</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow
								className="last-row-no-border"
								onClick={() => (updateGridFilter(false, null))}
								style={{ cursor: "pointer" }}
							>
								<TableCell variant="head">{t("gateway.total")}</TableCell>
								<TableCell variant="head" align="right">{devices.length}</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
			</Grid>
			<Grid item={true} xs={10}>
				<Paper style={{ overflow: "hidden" }}>
					<ZigbeeNetworkMap
						gatewayId={gatewayId}
						devices={devices}
						rocTableData={tableData}
					/>
					<Divider />
					<DataGrid
						loading={loading}
						columns={columns}
						rows={devices}
						onRowClick={handleRowClick}
						checkboxSelection={false}
						filterModel={{
							items: gridFilter,
						}}
						onFilterModelChange={handleOnFilterModelChange}
						sx={{
							"& .MuiDataGrid-row:hover": {
								cursor: "pointer",
							},
							"& .MuiDataGrid-actionsCell svg": {
								width: "26px",
								height: "26px",
							},
						}}
					/>
					<DeviceInfoDialog
						open={open}
						isThirdParty={isThirdParty}
						device={device}
						gatewayId={gatewayId}
						onClose={handleDialogCloseClick}
					/>
					<DeviceAttributesInfoDialog
						open={showAttributeDetailsDialog}
						device={device}
						onClose={() => (setShowAttributeDetailsDialog(false))}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default GatewayDevices;
